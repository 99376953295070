import { api } from './config'

async function registerUser(user) {
  const { data } = await api.post('/registration/candidate', user)

  return data
}

export {
  registerUser
}
