import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@web/constants/language'
import store from '@web/store'
import i18n from '@web/assets/i18n'

const setLocale = (to, from, next) => {
  const { params, query, path } = to
  const { locale, ...restParams } = params

  if (locale && !SUPPORTED_LOCALES.includes(locale)) {
    next({ path, params: { ...restParams, locale: undefined }, query })
  } else {
    if (locale !== from.params.locale) {
      store.dispatch('SET_APP_LANGUAGE', to.params.locale || DEFAULT_LOCALE)
    }

    next()
  }
}

const setRouteAlias = key => {
  if (!key) return undefined

  return SUPPORTED_LOCALES.filter(locale => locale !== DEFAULT_LOCALE).map(locale => i18n.t(key, locale))
}

const routes = [
  {
    path: `/:locale(${ SUPPORTED_LOCALES.join('|') })?`,
    component: {
      beforeRouteEnter: setLocale,
      beforeRouteUpdate: setLocale,
      render(h) {
        return h('router-view')
      }
    },
    children: [
      {
        name: 'auth-signin',
        path: 'prihlasit-sa',
        component: () => import(/* webpackChunkName: "auth-sign-in" */ '../auth/ka-sign-in-page'),
        meta: {
          layout: 'auth',
          title: 'meta.auth_sign_in',
          requiresGuest: true,
          translateKey: 'route_alias.auth_sign_in'
        }
      },
      {
        name: 'auth-password',
        path: 'zabudnute-heslo',
        component: () => import(/* webpackChunkName: "auth-forgot-password" */ '../auth/ka-forgot-password-page'),
        meta: {
          layout: 'auth',
          title: 'meta.auth_forgot_password',
          requiresGuest: true,
          translateKey: 'route_alias.auth_forgot_password'
        }
      },
      {
        name: 'auth-signup',
        path: 'registracia',
        component: () => import(/* webpackChunkName: "auth-sign-up" */ '../auth/ka-sign-up-page'),
        meta: {
          layout: 'auth',
          title: 'meta.auth_create_account',
          requiresGuest: true,
          translateKey: 'route_alias.auth_sign_up'
        }
      },
      {
        name: 'Home',
        path: '',
        component: () => import(/*webpackChunkName: "home"*/ '../home/pages/home-index'),
        meta: {
          title: null,
          layout: 'default',
          layoutProps: {
            noFooter: true
          },
          appBarStyle: 'solid'
        }
      },
      {
        name: 'project-index',
        path: 'praca',
        component: () => import(/* webpackChunkName: "project-index" */ '../projects/pages/project-index'),
        meta: {
          title: 'meta.work',
          translateKey: 'route_alias.project_index'
        }
      },
      {
        name: 'project-detail',
        path: 'praca/:id',
        component: () => import(/* webpackChunkName: "project-detail" */ '../projects/pages/project-detail'),
        meta: {
          title: 'meta.work',
          translateKey: 'route_alias.project_detail'
        }
      },
      {
        name: 'event-index',
        path: 'podujatia',
        component: () => import(/* webpackChunkName: "event-index" */ '../events/pages/event-index'),
        meta: {
          title: 'meta.events',
          appBarStyle: 'solid',
          layout: 'default',
          layoutProps: {
            noFooter: true
          },
          translateKey: 'route_alias.event_index'
        }
      },
      {
        name: 'event-detail',
        path: 'podujatia/:id',
        component: () => import(/* webpackChunkName: "event-detail" */ '../events/pages/event-detail'),
        meta: {
          title: 'meta.events',
          appBarStyle: 'transparent-light',
          layoutProps: {
            noFooter: true
          },
          translateKey: 'route_alias.event_detail'
        }
      },
      {
        name: 'blog-index',
        path: 'blog',
        component: () => import(/* webpackChunkName: "blog-index" */ '../blog/pages/blog-index'),
        meta: {
          title: 'meta.blog',
          appBarStyle: 'transparent-light',
          layout: 'default',
          layoutProps: {
            noFooter: true
          }
        }
      },

      {
        name: 'blog-detail',
        path: 'blog/:slug',
        component: () => import(/* webpackChunkName: "blog-detail" */ '../blog/pages/blog-detail'),
        meta: {
          title: 'meta.blog',
          appBarStyle: 'solid',
          layout: 'default'
        }
      },
      {
        name: 'newsfeed-index',
        path: 'newsfeed',
        component: () => import(/* webpackChunkName: "newsfeed-index" */ '../newsfeed/pages/newsfeed-index'),
        meta: {
          title: 'meta.newsfeed'
        }
      },
      {
        name: 'newsfeed-detail',
        path: 'newsfeed/:id',
        component: () => import(/* webpackChunkName: "newsfeed-detail" */ '../newsfeed/pages/newsfeed-detail'),
        meta: {
          title: 'meta.newsfeed'
        }
      },
      {
        name: 'Calculator',
        path: 'mzdova-kalkulacka',
        component: () => import(/* webpackChunkName: "calculator" */ '../calculator/calculator-page.vue'),
        meta: {
          title: 'meta.calculator',
          appBarStyle: 'solid',
          translateKey: 'route_alias.calculator'
        }
      },
      {
        name: 'cv-show',
        path: 'cv/:id',
        component: () => import(/*webpackChunkName: "cv"*/ '../cv/ka-user-cv-show-page'),
        meta: {
          title: 'meta.resume',
          appBarStyle: 'transparent-dark',
          layout: 'cv'
        }
      },
      {
        name: 'cv-landing-page',
        path: 'cv',
        component: () => import(/*webpackChunkName: "cv-landing"*/ '../cv/pages/cv-landing'),
        meta: {
          title: 'meta.resume',
          appBarStyle: 'solid'
        }
      },
      {
        name: 'dictionary-index',
        path: 'slovnik',
        component: () => import(/* webpackChunkName: "dictionary-index" */ '../dictionary/pages/dictionary-index'),
        meta: {
          title: 'meta.dictionary',
          appBarStyle: 'solid',
          translateKey: 'route_alias.dictionary_index'
        }
      },
      {
        name: 'dictionary-detail',
        path: 'slovnik/:id',
        component: () => import(/* webpackChunkName: "dictionary-detail" */ '../dictionary/pages/dictionary-detail'),
        meta: {
          title: 'meta.dictionary',
          appBarStyle: 'solid',
          translateKey: 'route_alias.dictionary_detail'
        }
      },
      {
        name: 'extra',
        path: 'extra',
        component: () => import(/* webpackChunkName: "extra" */ '../extra/extra-page'),
        meta: {
          title: 'meta.extra',
          appBarStyle: 'solid'
        }
      },
      {
        name: 'KoderiaAbout',
        path: 'koderia',
        component: () => import(/* webpackChunkName: "koderia-about" */ '../koderia-about/koderia-about-page'),
        meta: {
          title: 'meta.what_is_koderia',
          appBarStyle: 'solid'
        }
      },
      {
        name: 'AboutUs',
        path: 'o-nas',
        component: () => import(/* webpackChunkName: "about" */ '../about-us/about-us-page'),
        meta: {
          title: 'meta.about_us',
          appBarStyle: 'solid',
          translateKey: 'route_alias.about_us'
        }
      },
      {
        name: 'newsletter',
        path: 'newsletter',
        component: () => import(/* webpackChunkName: "newsletter" */ '../newsletter/newsletter-page'),
        meta: {
          title: 'meta.newsletter',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'default',
          layoutProps: {
            noFooter: true
          }
        }
      },
      {
        name: 'newsletter-mobile',
        path: 'newsletter-mobile',
        component: () => import(/* webpackChunkName: "newsletter-mobile" */ '../newsletter/newsletter-mobile-page'),
        meta: {
          title: 'meta.newsletter',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'default',
          layoutProps: {
            noFooter: true,
            noNavbar: true
          }
        }
      },
      {
        name: 'promo-index',
        path: 'nove-projekty',
        component: () => import(/* webpackChunkName: "promo-index" */ '../promo/pages/promo-index'),
        meta: {
          title: 'meta.promo',
          appBarStyle: 'solid',
          translateKey: 'route_alias.promo_index'
        }
      },
      {
        name: 'Questionaire',
        path: 'adekvatny-plat',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/questionaire-profession-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'solid',
          translateKey: 'route_alias.questionaire'
        }
      },
      {
        name: 'ThankYouQuestionaire',
        path: 'adekvatny-plat/dakujeme',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/thank-you-questionaire-page'),
        meta: {
          title: 'meta.adequate_salary_thank_you',
          appBarStyle: 'transparent-light',
          translateKey: 'route_alias.questionaire_thank_you'
        }
      },
      {
        name: 'MethodQuestionaire',
        path: 'adekvatny-plat/:queryProfession',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/questionaire-method-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'transparent-light',
          translateKey: 'route_alias.questionaire_method'
        }
      },
      {
        name: 'ProgrammerFormQuestionaire',
        path: 'adekvatny-plat/:programmer/formular',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/programmer-form-questionaire-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'transparent-light',
          translateKey: 'route_alias.questionaire_programmer_form'
        }
      },
      {
        name: 'TesterFormQuestionaire',
        path: 'adekvatny-plat/tester/formular',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/tester-form-questionaire-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'transparent-light',
          translateKey: 'route_alias.questionaire_tester_form'
        }
      },
      {
        name: 'CVQuestionaire',
        path: 'adekvatny-plat/:queryProfession/cv',
        component: () => import(/* webpackChunkName: "questionaire" */ '../questionaire/cv-questionaire-page'),
        meta: {
          title: 'meta.adequate_salary',
          appBarStyle: 'transparent-light',
          translateKey: 'route_alias.questionaire_cv'
        }
      },
      {
        name: 'referral',
        path: 'referral',
        component: () => import(/* webpackChunkName: "referral-index" */ '../referral/referral-page'),
        meta: {
          title: 'meta.referral',
          appBarStyle: 'solid'
        }
      },
      {
        name: 'referral-form',
        path: 'referral/formular',
        component: () => import(/* webpackChunkName: "referral-form" */ '../referral/referral-form-page'),
        meta: {
          title: 'meta.referral',
          appBarStyle: 'transparent-light',
          translateKey: 'route_alias.referral_form'
        }
      },
      {
        name: 'referral-form-project',
        path: 'referral/formular/:id',
        component: () => import(/* webpackChunkName: "referral-form-id" */ '../referral/referral-form-page'),
        meta: {
          title: 'meta.referral',
          appBarStyle: 'transparent-light',
          translateKey: 'route_alias.referral_form_project'
        }
      },
      {
        name: 'referral-approval',
        path: 'referral/suhlas/:id',
        component: () => import(/* webpackChunkName: "referral-approval" */ '../referral/referral-approval-page'),
        meta: {
          title: 'meta.referral_consent',
          appBarStyle: 'transparent-dark',
          translateKey: 'route_alias.referral_consent'
        }
      },
      {
        name: 'TermsAndConditions',
        path: 'ochrana-osobnych-udajov',
        component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../terms-and-conditions/terms-and-conditions-page.vue'),
        meta: {
          title: 'meta.privacy_policy',
          appBarStyle: 'solid',
          translateKey: 'route_alias.privacy_policy'
        }
      },
      {
        name: 'unsubscribe-index',
        path: 'unsubscribe',
        component: () => import(/* webpackChunkName: "unsubscribe-index" */ '../unsubscribe/pages/unsubscribe-index'),
        meta: {
          title: 'meta.unsubscribe'
        }
      },
      {
        name: 'profile',
        path: 'profil',
        component: () => import(/*webpackChunkName: "profile"*/ '../user/ka-user-profile-page'),
        meta: {
          title: 'meta.account',
          requiresAuth: true,
          layout: 'default',
          layoutProps: {
            noFooter: true
          },
          translateKey: 'route_alias.profile'
        }
      },
      {
        name: 'profile-saved',
        path: 'profil/ulozene',
        component: () => import(/*webpackChunkName: "profile-saved"*/ '../user/ka-user-saved-page'),
        meta: {
          title: 'meta.account_saved',
          requiresAuth: true,
          layout: 'default',
          layoutProps: {
            noFooter: true
          },
          translateKey: 'route_alias.profile_saved'
        }
      },
      {
        name: 'profile-added',
        path: 'profil/pridane',
        component: () => import(/*webpackChunkName: "profile-added"*/ '../user/ka-user-added-page'),
        meta: {
          title: 'meta.account_added',
          requiresAuth: true,
          layout: 'default',
          layoutProps: {
            noFooter: true
          },
          translateKey: 'route_alias.profile_added'
        }
      },
      {
        name: 'profile-cv',
        path: 'profil/cv',
        component: () => import(/*webpackChunkName: "profile-my-cv"*/ '../user/ka-user-cv-page'),
        meta: {
          title: 'meta.my_resume',
          requiresAuth: true,
          layout: 'default',
          layoutProps: {
            noFooter: true
          },
          translateKey: 'route_alias.profile_cv'
        }
      },
      {
        name: 'finish-signup',
        path: 'registracia/dokoncenie',
        component: () => import(/*webpackChunkName: "finish-signup"*/ '../user/ka-user-finish-signup'),
        meta: {
          title: 'meta.registration_completion',
          requiresAuth: true,
          translateKey: 'route_alias.finish_signup'
        }
      },
      {
        name: 'WorkComparison',
        path: 'porovnanie-foriem-prace',
        component: () => import(/* webpackChunkName: "work-comparison" */ '../work-comparison/work-comparison-page.vue'),
        meta: {
          title: 'meta.work_types_comparison',
          appBarStyle: 'solid',
          translateKey: 'route_alias.work_types_comparison'
        }
      },
      {
        name: 'frame-MestoBratislava',
        path: 'MestoBratislava',
        component: () => import('../frames/frame-mesto-bratislava'),
        meta: {
          title: 'meta.frame_help_bratislava',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'frame',
          translateKey: 'route_alias.frame_town'
        }
      },
      {
        name: 'frame-PracujVHernomPriemysle',
        path: 'pracuj-v-hernom-priemysle',
        component: () => import('../frames/frame-pracuj-v-hernom-priemysle'),
        meta: {
          title: 'meta.frame_game_industry',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'frame',
          translateKey: 'route_alias.frame_game_industry'
        }
      },
      {
        name: 'frame-NetProjekty',
        path: 'net-projekty',
        component: () => import('../frames/frame-net-projekty'),
        meta: {
          title: 'meta.frame_net_teams',
          appBarStyle: 'transparent-dark/light-logo',
          layout: 'frame',
          translateKey: 'route_alias.frame_net'
        }
      },
      {
        name: 'Page404',
        path: '*',
        component: () => import(/*webpackChunkName: "404"*/ '../404/404page.vue'),
        meta: {
          title: 'meta.404'
        }
      }
    ]
  }
]

routes[0].children = routes[0].children.map(route => ({
  ...route,
  alias: setRouteAlias(route.meta.translateKey)
}))

export default routes
