import 'firebase/firestore'

import store from '../store'
import { fetchProjectDetail, fetchProjects } from '@web/api/project-api'
import dayjs from 'dayjs'
import router from '@web/router'
import { cloneDeep } from 'lodash'
import { DEFAULT_LOCALE } from '@web/constants/language'
import {
  storeActionStatusCreator,
  storeGetterStatusCreator,
  storeMutationStatusCreator,
  storeStateStatusesCreator
} from '@web/utils/store-status-creator'
import { applyUserForJob, applyUserForJobUploadCv } from '@web/api/user-api'
import { carrierProjects } from '@web/projects/constants'

const projectStatuses = ['projectsStatus', 'projectStatus', 'applyForJobStatus']

const initialState = {
  projects: [],
  projectsCountByProfessions: [],
  projectDetail: null,
  isCarrierProject: false,
  filters: {
    professions: []
  },
  appliedFilters: {
    profession: null,
    isRemoteOnly: false,
    searchTerm: ''
  },
  ...storeStateStatusesCreator(projectStatuses)
}

const actions = {
  async fetchAllProjects({ commit }, payload) {
    commit('setStatusLoading', 'projectsStatus')

    try {
      const response = await fetchProjects(payload?.filter)

      commit('setProjects', response.page.content)
      commit('setProfessionFilters', response.filters.professions)
      commit('setStatusSuccess', 'projectsStatus')
    } catch (e) {
      commit('setStatusFail', { statusName: 'projectsStatus', error: e.message })
    }
  },
  async fetchProjectDetail({ commit, rootGetters }, payload) {
    const carrierIndex = carrierProjects.findIndex(project => project.id === payload.id)

    if (carrierIndex >= 0) {
      commit('setProjectDetail', carrierProjects[carrierIndex])
      commit('setStatusSuccess', 'projectStatus')
      commit('setIsCarrierProject', true)

      return
    }

    commit('setStatusLoading', 'projectStatus')
    commit('setIsCarrierProject', false)

    try {
      const response = await fetchProjectDetail(payload.id)

      commit('setProjectDetail', response)
      commit('setStatusSuccess', 'projectStatus')
    } catch (e) {
      console.error(e)
      const appLocale = rootGetters.appLocale
      const locale = appLocale === DEFAULT_LOCALE ? undefined : appLocale

      router.push({ name: 'Page404', params: { '0': '', locale } })
    }
  },
  async applyForJob({ commit }, newJobApplication) {
    commit('setStatusLoading', 'applyForJobStatus')

    try {
      const { file, ...rest } = newJobApplication
      let fileId = null

      if (file) {
        const fileResponse = await applyUserForJobUploadCv(file)

        fileId = fileResponse.id
      }

      const jobApplication = await applyUserForJob({ ...rest, fileId })

      store.commit('USER/addNewJobApplication', jobApplication)
      store.commit('USER/updateUserProfile', rest)

      commit('setStatusSuccess', 'applyForJobStatus')
    } catch (e) {
      commit('setStatusFail', { statusName: 'applyForJobStatus', error: e.message })
    }
  },
  setRouteQueryAndFetch({ commit, dispatch }, payload) {
    commit('setAppliedFilters', payload.filter)

    const filter = {
      profession: payload.filter.profession || undefined,
      isRemoteOnly: payload.filter.isRemoteOnly || undefined,
      searchTerm: payload.filter.searchTerm || undefined
    }

    dispatch('fetchAllProjects', { filter })

    const { name, params } = router.currentRoute
    const newQuery = {
      profession: payload.filter.profession || undefined,
      remote: payload.filter.isRemoteOnly ? '1' : undefined,
      search: encodeURIComponent(payload.filter.searchTerm) || undefined
    }

    router.push({ name, params, query: newQuery })
  },
  ...storeActionStatusCreator()
}

const getters = {
  getAllProjects: state => state.projects,
  getProjectDetail: state => state.projectDetail,
  getAllTypes: (state, getters) => {
    const unique = new Set(getters.getAllProjects.map(project => project.type))

    return [...unique].sort((t1, t2) => t1.localeCompare(t2))
  },
  getProfessionsWithCount: (state) => state.filters.professions,
  getAppliedFilters: (state) => ({ ...state.appliedFilters }),
  getIsCarrierProject: (state) => state.isCarrierProject,
  ...storeGetterStatusCreator()
}

const mutations = {
  setProjects(state, payload) {
    state.projects = payload.map(project => ({
      ...project,
      isNew: dayjs().diff(dayjs(project.publishedAt), 'days') <= 7
    }))
  },
  setProfessionFilters(state, payload) {
    state.filters.professions = payload
  },
  setAppliedFilters(state, payload) {
    state.appliedFilters = payload
  },
  setProjectDetail(state, payload) {
    state.projectDetail = payload
  },
  setIsCarrierProject(state, payload) {
    state.isCarrierProject = payload
  },
  ...storeMutationStatusCreator()
}

store.registerModule('NEW_PROJECTS', {
  namespaced: true,
  state: cloneDeep(initialState),
  actions,
  getters,
  mutations
})
