import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import KClose from '@web/components/core/k-close'
import KoderiaClose from '@web/components/core/icons/koderia_close'
import KoderiaRadioOn from '@web/components/core/icons/koderia_radio_on'
import KoderiaRadioOff from '@web/components/core/icons/koderia_radio_off'
import KoderiaCheckboxOn from '@web/components/core/icons/koderia_checkbox_on'
import KoderiaChevronLeft from '@web/components/core/icons/koderia_chevron_left'
import KoderiaChevronDown from '@web/components/core/icons/koderia_chevron_down'
import KoderiaCheckboxOff from '@web/components/core/icons/koderia_checkbox_off'
import KoderiaChevronRight from '@web/components/core/icons/koderia_chevron_right'
import KoderiaCheckboxIndeterminate from '@web/components/core/icons/koderia_checkbox_indeterminate'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    disable: true
  },
  icons: {
    iconfont: 'mdi',
    values: {
      dropdown: {
        component: KoderiaChevronDown
      },
      prev: {
        component: KoderiaChevronLeft
      },
      next: {
        component: KoderiaChevronRight
      },
      checkboxOn: {
        component: KoderiaCheckboxOn,
        props: {
          size: 24,
          color: '#0ea66d'
        }
      },
      checkboxOff: {
        component: KoderiaCheckboxOff,
        props: {
          size: 24,
          color: '#141a1d'
        }
      },
      checkboxIndeterminate: {
        component: KoderiaCheckboxIndeterminate,
        props: {
          size: 24,
          color: '#141a1d'
        }
      },
      radioOn: {
        component: KoderiaRadioOn,
        props: {
          size: 24,
          color: '#0ea66d'
        }
      },
      radioOff: {
        component: KoderiaRadioOff,
        props: {
          size: 24,
          color: '#141a1d'
        }
      },
      clear: {
        component: KoderiaClose,
        props: {
          size: 18
        }
      },
      delete: {
        component: KClose,
        props: {
          size: 24
        }
      }
    }
  }
})
