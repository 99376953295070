<script>
import KoderiaCv from './icons/koderia_cv'
import KoderiaAdd from './icons/koderia_add'
import KoderiaPdf from './icons/koderia_pdf'
import KoderiaStar from './icons/koderia_star'
import KoderiaPlus from './icons/koderia_plus'
import KoderiaUser from './icons/koderia_user'
import KoderiaCopy from './icons/koderia_copy'
import KoderiaDone from './icons/koderia_done'
import KoderiaEdit from './icons/koderia_edit'
import KoderiaEuro from './icons/koderia_euro'
import KoderiaInfo from './icons/koderia_info'
import KoderiaMenu from './icons/koderia_menu'
import KoderiaMore from './icons/koderia_more'
import KoderiaSave from './icons/koderia_save'
import KoderiaWork from './icons/koderia_work'
import KoderiaLink from './icons/koderia_link'
import KoderiaBook from './icons/koderia_book'
import KoderiaAdded from './icons/koderia_added'
import KoderiaClose from './icons/koderia_close'
import KoderiaEmail from './icons/koderia_email'
import KoderiaHeart from './icons/koderia_heart'
import KoderiaPhone from './icons/koderia_phone'
import KoderiaSaved from './icons/koderia_saved'
import KoderiaSkill from './icons/koderia_skill'
import KoderiaTrash from './icons/koderia_trash'
import KoderiaLaptop from './icons/koderia_laptop'
import KoderiaAttach from './icons/koderia_attach'
import KoderiaHidden from './icons/koderia_hidden'
import KoderiaSearch from './icons/koderia_search'
import KoderiaRocket from './icons/koderia_rocket'
import KoderiaSpotify from './icons/koderia_spotify'
import KoderiaVisible from './icons/koderia_visible'
import KoderiaWarning from './icons/koderia_warning'
import KoderiaRadioOn from './icons/koderia_radio_on'
import KoderiaDownload from './icons/koderia_download'
import KoderiaFacebook from './icons/koderia_facebook'
import KoderiaLinkedin from './icons/koderia_linkedin'
import KoderiaLocation from './icons/koderia_location'
import KoderiaLanguage from './icons/koderia_language'
import KoderiaRadioOff from './icons/koderia_radio_off'
import KoderiaUserEdit from './icons/koderia_user_edit'
import KoderiaInstagram from './icons/koderia_instagram'
import KoderiaListCheck from './icons/koderia_list_check'
import KoderiaChevronUp from './icons/koderia_chevron_up'
import KoderiaDictionary from './icons/koderia_dictionary'
import KoderiaCalculator from './icons/koderia_calculator'
import KoderiaLayerGroup from './icons/koderia_layer_group'
import KoderiaCheckboxOn from './icons/koderia_checkbox_on'
import KoderiaPaperPlane from './icons/koderia_paper_plane'
import KoderiaCertificate from './icons/koderia_certificate'
import KoderiaCheckboxOff from './icons/koderia_checkbox_off'
import KoderiaChevronDown from './icons/koderia_chevron_down'
import KoderiaChevronLeft from './icons/koderia_chevron_left'
import KoderiaHeartBorder from './icons/koderia_heart_border'
import KoderiaArrowDropUp from './icons/koderia_arrow_drop_up'
import KoderiaChevronRight from './icons/koderia_chevron_right'
import KoderiaUserGraduate from './icons/koderia_user_graduate'
import KoderiaArrowDropDown from './icons/koderia_arrow_drop_down'
import KoderiaArrowFromSquare from './icons/koderia_arrow_from_square'
import KoderiaCheckboxIndeterminate from './icons/koderia_checkbox_indeterminate'

export default {
  props: {
    name: {
      type: String
    },
    color: {
      type: String,
      default: 'currentColor'
    },
    size: {
      type: [Number, String],
      default: 24
    }
  },
  render(createElement) {
    let map = new Map()

    map.set('cv', KoderiaCv)
    map.set('add', KoderiaAdd)
    map.set('pdf', KoderiaPdf)
    map.set('star', KoderiaStar)
    map.set('user', KoderiaUser)
    map.set('plus', KoderiaPlus)
    map.set('copy', KoderiaCopy)
    map.set('done', KoderiaDone)
    map.set('edit', KoderiaEdit)
    map.set('euro', KoderiaEuro)
    map.set('info', KoderiaInfo)
    map.set('menu', KoderiaMenu)
    map.set('more', KoderiaMore)
    map.set('save', KoderiaSave)
    map.set('work', KoderiaWork)
    map.set('link', KoderiaLink)
    map.set('book', KoderiaBook)
    map.set('added', KoderiaAdded)
    map.set('close', KoderiaClose)
    map.set('email', KoderiaEmail)
    map.set('heart', KoderiaHeart)
    map.set('phone', KoderiaPhone)
    map.set('saved', KoderiaSaved)
    map.set('skill', KoderiaSkill)
    map.set('trash', KoderiaTrash)
    map.set('rocket', KoderiaRocket)
    map.set('attach', KoderiaAttach)
    map.set('hidden', KoderiaHidden)
    map.set('search', KoderiaSearch)
    map.set('laptop', KoderiaLaptop)
    map.set('spotify', KoderiaSpotify)
    map.set('visible', KoderiaVisible)
    map.set('warning', KoderiaWarning)
    map.set('radio_on', KoderiaRadioOn)
    map.set('download', KoderiaDownload)
    map.set('facebook', KoderiaFacebook)
    map.set('linkedin', KoderiaLinkedin)
    map.set('location', KoderiaLocation)
    map.set('radio_on', KoderiaRadioOff)
    map.set('language', KoderiaLanguage)
    map.set('user_edit', KoderiaUserEdit)
    map.set('instagram', KoderiaInstagram)
    map.set('list_check', KoderiaListCheck)
    map.set('chevron_up', KoderiaChevronUp)
    map.set('calculator', KoderiaCalculator)
    map.set('dictionary', KoderiaDictionary)
    map.set('paper_plane', KoderiaPaperPlane)
    map.set('checkbox_on', KoderiaCheckboxOn)
    map.set('layer_group', KoderiaLayerGroup)
    map.set('certificate', KoderiaCertificate)
    map.set('checkbox_off', KoderiaCheckboxOff)
    map.set('chevron_down', KoderiaChevronDown)
    map.set('chevron_left', KoderiaChevronLeft)
    map.set('heart_border', KoderiaHeartBorder)
    map.set('arrow_drop_up', KoderiaArrowDropUp)
    map.set('chevron_right', KoderiaChevronRight)
    map.set('user_graduate', KoderiaUserGraduate)
    map.set('link_outside', KoderiaArrowFromSquare)
    map.set('arrow_drop_down', KoderiaArrowDropDown)
    map.set('checkbox_indeterminate', KoderiaCheckboxIndeterminate)

    const Component = map.get(String(this.name).toLowerCase())

    if (Component) {
      return createElement(Component, {
        attrs: this.$attrs,
        on: this.$listeners,
        props: {
          ...this.$props,
          color: this.color,
          size: this.size
        }
      })
    }

    throw new Error(`No icon found with name: "${ this.name }"`)
  }
}
</script>

<style></style>
