import { DEFAULT_LOCALE } from '@web/constants/language'
import router from '@web/router'

export const routerReplaceAfterLanguageChange = locale => {
  const { name, params, query } = router.currentRoute
  const { locale: paramLocale, ...restParams } = params
  const newParams = locale === DEFAULT_LOCALE ? restParams : { ...restParams, locale }

  router.replace({ name, params: newParams, query })
}

export const encodeRouteParam = param =>
  String(param)
    .split(' ')
    .join('-')
    .replace('/', '_')
    .toLowerCase()

export const decodeRouteParams = param =>
  String(param)
    .split('-')
    .join(' ')
    .replace('_', '/')
