import Vue from 'vue'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

import store from '../store'

const STORE_STATE = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

const initialState = {
  eventsById: {},
  collectionLoaded: false,
  storeState: {
    loadingCollection: STORE_STATE.IDLE,
    loadingDocument: STORE_STATE.IDLE,
    creatingDocument: STORE_STATE.IDLE,
    editingDocument: STORE_STATE.IDLE
  },
  storeStateMessage: {
    loadingCollection: undefined,
    loadingDocument: undefined,
    creatingDocument: undefined,
    editingDocument: undefined
  }
}

const afterTimeout = (cb, timeout = 2500) => {
  setTimeout(cb, timeout)
}

const ALLOWED_ATTRIBUTES = [
  'uid',
  'name',
  'description',
  'isOnline',
  'url',
  'imageUrl',
  'ticketUrl',
  'startAt',
  'endAt',
  'organizator',
  'createdAt',
  'canceledAt'
]

const eventsConverter = {
  toFirestore: modelObject => {
    const output = Object.entries(modelObject)
      .filter(([key, _value]) => ALLOWED_ATTRIBUTES.includes(key))
      .reduce((accumulator, [key, value]) => {
        accumulator[key] = value
        return accumulator
      }, {})

    if (!modelObject.isOnline && modelObject.location && modelObject.location.googlePlaceId) {
      output.location = {
        name: modelObject.location.name || null,
        googlePlaceId: modelObject.location.googlePlaceId || null
      }
      output.locationId = modelObject.location.locationId || null
    }

    return output
  },
  fromFirestore: (snapshot, options) => {
    if (snapshot.exists) {
      const data = snapshot.data(options) || {}
      const { startAt, endAt } = data
      return {
        ...data,
        startAtDate: startAt ? startAt.toDate() : null,
        endAtDate: endAt ? endAt.toDate() : null,
        id: snapshot.id
      }
    }

    return null
  }
}

const fetchAllEvents = async () => {
  const collectionSnapshot = await firebase
    .firestore()
    .collection('events')
    .withConverter(eventsConverter)
    .where('endDate', '>=', new Date().toISOString())
    .get()

  return collectionSnapshot.docs.map(doc => doc.data())
}

const fetchEventById = async eventId => {
  const snapshot = await firebase
    .firestore()
    .collection('events')
    .withConverter(eventsConverter)
    .doc(eventId)
    .get()

  return snapshot.data()
}

const makeid = (length = 64) => {
  var text = ''
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))
  return text
}

const uploadImage = async image => {
  if (image) {
    try {
      const uploadImageRef = firebase.storage().ref(`/events/${ makeid() }`)
      const snapshotTask = await uploadImageRef.put(image)
      return await snapshotTask.ref.getDownloadURL()
    } catch (error) {
      return null
    }
  }

  return null
}

const actions = {
  async getAllEvents({ commit, getters, state }) {
    if (state.loaded) {
      return getters.events
    }
    commit('SET_STORE_STATE', { name: 'loadingCollection', state: STORE_STATE.PENDING })
    try {
      const events = await fetchAllEvents()
      commit('SET_EVENTS', events)
      return events
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_STORE_STATE', { name: 'loadingCollection', state: STORE_STATE.IDLE })
    }
  },

  async getEventById({ commit, getters }, eventId) {
    if (getters.eventById(eventId)) {
      return getters.eventById(eventId)
    }
    commit('SET_STORE_STATE', { name: 'loadingDocument', state: STORE_STATE.PENDING })
    try {
      const event = await fetchEventById(eventId)
      commit('ADD_EVENT', event)
      return event
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_STORE_STATE', { name: 'loadingDocument', state: STORE_STATE.IDLE })
    }
  },

  // TODO: needs refactoring and simplifies the logic
  async updateEventSimple({ commit }, payload) {
    commit('SET_STORE_STATE', { name: 'editingDocument', state: STORE_STATE.PENDING })

    try {
      await firebase
        .firestore()
        .collection('events')
        .withConverter(eventsConverter)
        .doc(payload.id)
        .set({ ...payload }, { merge: true })

      // Re-fetch Event
      const event = await fetchEventById(payload.id)
      commit('ADD_EVENT', event)

      // On Success
      commit('SET_STORE_STATE', { name: 'editingDocument', state: STORE_STATE.SUCCESS })
      afterTimeout(() => {
        commit('SET_STORE_STATE', { name: 'editingDocument', state: STORE_STATE.IDLE })
      })
    } catch (error) {
      // On Error
      console.error(error)
      commit('SET_STORE_STATE', { name: 'editingDocument', state: STORE_STATE.ERROR })
    }
  },

  // TODO: needs refactoring and simplifies the logic / prepare data and use updateEventSimple
  async updateEvent({ commit, dispatch }, payload) {
    commit('SET_STORE_STATE', { name: 'editingDocument', state: STORE_STATE.PENDING })

    try {
      const imageUrl = payload.image ? await uploadImage(payload.image) : payload.imageUrl
      const orgImageUrl = payload.orgImage ? await uploadImage(payload.orgImage) : payload.orgImageUrl

      await dispatch('updateUserOrganizer', {
        url: payload.orgUrl || null,
        name: payload.orgName || null,
        imageUrl: orgImageUrl || null,
        description: payload.orgDescription || null
      })

      const dataToSave = {
        ...payload,
        imageUrl,
        organizator: {
          url: payload.orgUrl || null,
          name: payload.orgName || null,
          imageUrl: orgImageUrl || null,
          description: payload.orgDescription || null
        }
      }

      await firebase
        .firestore()
        .collection('events')
        .withConverter(eventsConverter)
        .doc(payload.id)
        .set(dataToSave, { merge: true })

      // firebase.functions().httpsCallable("emitEvent")({
      //     type: "EVENT",
      //     data: dataToSave
      // });

      // Re-fetch Event
      const event = await fetchEventById(payload.id)
      commit('ADD_EVENT', event)

      // On Success
      commit('SET_STORE_STATE', { name: 'editingDocument', state: STORE_STATE.SUCCESS })
      afterTimeout(() => {
        commit('SET_STORE_STATE', { name: 'editingDocument', state: STORE_STATE.IDLE })
      })
    } catch (error) {
      // On Error
      console.error(error)
      commit('SET_STORE_STATE', { name: 'editingDocument', state: STORE_STATE.ERROR })
    }
  },

  async restoreEvent({ dispatch }, eventId) {
    dispatch('updateEventSimple', {
      id: eventId,
      canceledAt: firebase.firestore.FieldValue.delete()
    })
  },

  async cancelEvent({ dispatch }, eventId) {
    dispatch('updateEventSimple', {
      id: eventId,
      canceledAt: firebase.firestore.Timestamp.now()
    })
  },

  // TODO: needs refactoring and simplifies the logic
  async createEvent({ commit, dispatch }, payload) {
    commit('SET_STORE_STATE', { name: 'creatingDocument', state: STORE_STATE.PENDING })

    const {
      uid,
      name,
      description,
      orgName,
      orgDescription,
      orgImage,
      orgImageUrl,
      image,
      ticketUrl,
      orgUrl,
      startAt,
      endAt,
      location = {},
      isOnline,
      url
    } = payload

    try {
      const imageUrl = await uploadImage(image)
      let newOrgImageUrl = orgImage ? await uploadImage(orgImage) : orgImageUrl

      const getEndAt = () => {
        if (endAt) {
          return firebase.firestore.Timestamp.fromDate(endAt)
        } else if (startAt) {
          const date = new Date(startAt.getTime())
          date.setHours(date.getHours() + 1)
          return firebase.firestore.Timestamp.fromDate(date)
        } else {
          return null
        }
      }

      const payload = {
        uid: uid || null,
        name: name || null,
        description: description || null,
        imageUrl: imageUrl || null,
        ticketUrl: ticketUrl || null,
        startAt: startAt ? firebase.firestore.Timestamp.fromDate(startAt) : null,
        endAt: getEndAt(),
        isOnline: !!isOnline,
        url: url || null,
        location: {
          name: (location && location.name) || null,
          googlePlaceId: (location && location.googlePlaceId) || null
        },
        locationId: (location && location.locationId) || null,
        organizator: {
          url: orgUrl || null,
          name: orgName || null,
          imageUrl: newOrgImageUrl || null,
          description: orgDescription || null
        },
        stats: {
          visits: 0
        },
        createdAt: firebase.firestore.Timestamp.now()
      }

      const document = await firebase
        .firestore()
        .collection('events')
        .add(payload)

      dispatch('updateUserOrganizer', {
        url: orgUrl || null,
        name: orgName || null,
        imageUrl: newOrgImageUrl || null,
        description: orgDescription || null
      })

      // On Success
      commit('SET_STORE_STATE', { name: 'creatingDocument', state: STORE_STATE.SUCCESS })
      afterTimeout(() => {
        commit('SET_STORE_STATE', { name: 'creatingDocument', state: STORE_STATE.IDLE })
      }, 6000)

      // firebase.functions().httpsCallable("emitEvent")({
      //     type: "EVENT",
      //     data: payload
      // });

      return document
    } catch (error) {
      console.error(error)
      // On Error
      commit('SET_STORE_STATE', { name: 'creatingDocument', state: STORE_STATE.ERROR, message: error.message })
    }
  },

  async updateUserOrganizer(ctx, payload = {}) {
    store.dispatch('USER/UPDATE_PROFILE', { eventOrganizator: { ...payload } })
  }
}

const getters = {
  events: state => {
    const events = Object.values(state.eventsById || {})
    return events.sort((e1, e2) => String(e1.startDate).localeCompare(e2.startDate))
  },
  eventsCount: (state, getters) => {
    return getters.events.length
  },
  upcomingOnlineEvents: (state, getters) => {
    return getters.events.filter(event => event.countryCode === 'ON')
  },
  upcomingLocalEvents: (state, getters) => {
    return getters.events.filter(event => event.countryCode !== 'ON')
  },
  upcomingNotOnlineEvents: (state, getters) => {
    return getters.upcomingEvents.filter(event => !event.isOnline)
  },
  upcomingEventsLocations: (state, getters) => {
    return getters.upcomingNotOnlineEvents.map(event => event.locationId).sort()
  },
  upcomingEventsByLocation: (state, getters) => locationId => {
    return getters.upcomingNotOnlineEvents.filter(event => event.locationId === locationId)
  },
  eventById: state => eventId => {
    const { [eventId]: event } = state.eventsById || {}
    return event
  },
  isStoreState: state => (stateName, checkState) => {
    return state.storeState[stateName] === checkState
  }
}

const mutations = {
  ADD_EVENT(state, payload) {
    if (payload && payload.id) {
      Vue.set(state.eventsById, payload.id, payload)
    }
  },
  SET_EVENTS(state, payload) {
    (payload || []).forEach(event => {
      if (event && event.id) {
        Vue.set(state.eventsById, event.id, event)
      }
    })
    state.loaded = true
  },
  SET_STORE_STATE(state, { name, state: newState, message }) {
    if (name === '*') {
      Object.keys(state.formState).forEach(key => {
        Vue.set(state.storeState, key, newState)
        Vue.set(state.storeStateMessage, key, message || undefined)
      })
    } else {
      Vue.set(state.storeState, name, newState)
      Vue.set(state.storeStateMessage, name, message || undefined)
    }
  }
}

store.registerModule('EVENTS', {
  namespaced: true,
  state: Object.assign({}, initialState),
  actions,
  mutations,
  getters
})
