import { render, staticRenderFns } from "./ka-user-avatar.vue?vue&type=template&id=100e0c8d&"
import script from "./ka-user-avatar.vue?vue&type=script&lang=js&"
export * from "./ka-user-avatar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KIcon from '@web/components/core/k-icon'
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {KIcon,VAvatar,VImg})
