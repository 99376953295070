import sk from 'date-fns/locale/sk'
import en from 'date-fns/locale/en-US'
import store from '@web/store'

export const DEFAULT_LOCALE = 'sk'
export const SUPPORTED_LOCALES = ['sk', 'en']

const LanguageLocales = {
  sk,
  en
}

export const getRouteLocale = () => {
  const locale = store.getters.appLocale

  return locale === DEFAULT_LOCALE ? undefined : locale
}

export const getLanguageLocale = () => {
  const locale = store.getters.appLocale

  return LanguageLocales[locale]
}
