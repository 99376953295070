import { api } from './config'

async function fetchProjects(filter) {
  const { data } = await api.post(
    '/job-descriptions',
    {
      filter,
      sort: {
        orders: [
          {
            property: 'top_order',
            direction: 'ASC'
          },
          {
            property: 'publication_date',
            direction: 'DESC'
          }
        ]
      }
    })

  return data
}

async function fetchProjectDetail(id) {
  const { data } = await api.get(`/job-descriptions/${ id }`)

  return data
}

export {
  fetchProjects,
  fetchProjectDetail
}
