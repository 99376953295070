import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=2fd999d2&"
import script from "./auth.vue?vue&type=script&lang=js&"
export * from "./auth.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KLanguageSwitcher from '@web/components/core/k-language-switcher'
import KLogo from '@web/components/core/k-logo'
installComponents(component, {KLanguageSwitcher,KLogo})
