<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      d="M13,15.917A5.4,5.4,0,0,1,8.2,13H13V11.333H7.65a5.242,5.242,0,0,1,0-1.667H13V8H8.2a5.406,5.406,0,0,1,8.325-1.608L18,4.917A7.489,7.489,0,0,0,5.933,8H3V9.667H5.55a6.969,6.969,0,0,0,0,1.667H3V13H5.933A7.489,7.489,0,0,0,18,16.083l-1.483-1.475A5.338,5.338,0,0,1,13,15.917Z"
      transform="translate(-0.5 -0.5)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#374151'
    },
    size: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
