<template>
  <v-layout row justify-center>
    <v-dialog :value="value" @input="handleShow" max-width="824">
      <v-card v-if="success" class="dialog">
        <h3 class="">{{ $t('profile.subtitles.thank_you') }}</h3>
        <hr class="kod-hr kod-hr--full" />
        <p class="mt-8 body-text">
          {{ $t('profile.descriptions.approval_of_contribution') }}
        </p>
        <div class="mt-10">
          <k-button class="btn-kod btn-success" :width="100" @click="handleShow(false)">
            OK
          </k-button>
        </div>
      </v-card>

      <form v-else @submit.prevent="submit">
        <v-card class="dialog">
          <h3>{{ $t('profile.actions.add_post') }}</h3>
          <hr class="kod-hr" />
          <p class="mt-8 body-text">{{ $t('profile.descriptions.share_original_posts') }}</p>
          <k-input
            class="mt-6 value-input"
            autofocus
            v-model="inputValue"
            :placeholder="$t('profile.inputs.url_link')"
            :invalid="submitted && !inputValue"
            :style="isScreenLessThen(830) ? 'max-width: 100%!important' : ''"
          />
          <div class="mt-12">
            <k-button
              :block="isScreenLessThen(830)"
              class="btn-kod btn-success"
              :loading="formState === 'PENDING'"
              type="submit"
              :width="110"
            >
              {{ $t('global.actions.share') }}
            </k-button>
          </div>
          <v-img src="./icons/user.svg" class="image" />
        </v-card>
      </form>
    </v-dialog>
  </v-layout>
</template>

<script>
import './store'
import responsiveMixin from './../responsiveMixin'

export default {
  mixins: [responsiveMixin],
  props: ['value'],
  data() {
    return {
      submitted: false,
      success: false,
      inputValue: null
    }
  },
  computed: {
    formState: function () {
      return this.$store.state.USER.formState.submitPost
    },
    formStateMessage: function () {
      return this.$store.state.USER.formStateMessage.submitPost
    }
  },
  watch: {
    value() {
      this.inputValue = null
    }
  },
  methods: {
    handleShow(value) {
      this.$emit('input', value)
      this.submitted = false
      this.success = false
    },
    submit() {
      this.submitted = true
      if (this.inputValue && this.inputValue.trim().length > 0) {
        this.$store.dispatch('USER/SUBMIT_POST', this.inputValue).then(() => (this.success = true))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  overflow: visible;
}

.dialog {
  padding: 3.5rem 4rem 4rem 4rem;
  @media (max-width: 40rem) {
    padding: 1.5rem 1rem;
  }
  border-radius: 0.5rem !important;
  position: relative;
  overflow: visible !important;
}

.image {
  position: absolute;
  bottom: -0.438rem;
  right: 0;
  width: 21.75rem;
  height: 21.438rem;
  display: none;
}

.kod-hr {
  margin-top: 1rem !important;
}

.kod-hr--full {
  max-width: 100% !important;
}

@media (min-width: 51.875rem) {
  .kod-hr {
    max-width: 30rem;
  }

  .image {
    display: block;
  }
}

.value-input {
  width: 100%;
  max-width: 26.5rem;
  max-height: 21.5rem;
}
</style>
