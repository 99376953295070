<template>
  <svg :width="size" :height="size" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g :fill="color">
      <path
        d="M10.833,2A5.829,5.829,0,0,0,5,7.833c0,4.375,5.833,10.833,5.833,10.833s5.833-6.458,5.833-10.833A5.829,5.829,0,0,0,10.833,2ZM6.667,7.833a4.167,4.167,0,1,1,8.333,0c0,2.4-2.4,5.992-4.167,8.233C9.1,13.842,6.667,10.208,6.667,7.833Z"
        transform="translate(-0.833 -0.333)"
      />
      <circle cx="2.083" cy="2.083" r="2.083" transform="translate(7.917 5.417)" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#374151'
    },
    size: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
