import { api } from './config'

async function fetchExpertisesByName(name) {
  const { data } = await api.get(`/expertises?name=${ name }`)

  return data
}

async function fetchEnums() {
  const { data } = await api.get('/enums')

  return data
}

export {
  fetchExpertisesByName,
  fetchEnums
}
