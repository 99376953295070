export const storeStateStatusesCreator = (statuses) => {
  return statuses.reduce((acc, status) => ({
    ...acc,
    [status]: {
      isLoading: false,
      isSuccess: false,
      error: null
    }
  }), {})
}

export const storeGetterStatusCreator = () => ({
  getStatus: state => statusName => ({
    isLoading: state[statusName].isLoading,
    isSuccess: state[statusName].isSuccess,
    error: state[statusName].error
  })
})

export const storeActionStatusCreator = () => ({
  clearStatus({ commit }, statusName) {
    commit('setStatusClear', statusName)
  }
})

export const storeMutationStatusCreator = () => ({
  setStatusLoading(state, statusName) {
    state[statusName].isLoading = true
    state[statusName].isSuccess = false
    state[statusName].errorMessage = null
  },
  setStatusSuccess(state, statusName) {
    state[statusName].isLoading = false
    state[statusName].isSuccess = true
    state[statusName].errorMessage = null
  },
  setStatusFail(state, { statusName, error }) {
    state[statusName].isLoading = false
    state[statusName].isSuccess = false
    state[statusName].error = error
  },
  setStatusClear(state, statusName) {
    state[statusName].isLoading = false
    state[statusName].isSuccess = false
    state[statusName].error = null
  }
})
