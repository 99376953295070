<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      d="M22.286,32H1.709A1.722,1.722,0,0,0,0,33.73V54.27A1.722,1.722,0,0,0,1.709,56H22.286A1.726,1.726,0,0,0,24,54.27V33.73A1.726,1.726,0,0,0,22.286,32ZM7.254,52.571H3.7V41.118H7.259V52.571ZM5.475,39.554a2.063,2.063,0,1,1,2.063-2.062A2.063,2.063,0,0,1,5.475,39.554ZM20.587,52.571H17.03V47c0-1.329-.027-3.037-1.848-3.037-1.854,0-2.137,1.446-2.137,2.941v5.668H9.488V41.118H12.9v1.564h.048a3.747,3.747,0,0,1,3.37-1.848c3.6,0,4.27,2.373,4.27,5.459Z"
      transform="translate(0 -32)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#374151'
    },
    size: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
