<template>
  <v-menu content-class="tw-mt-8 tw-duration-200 tw-shadow-none tw-overflow-visible tw-p-2 tw-font-sans">
    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
        class="tw-flex tw-items-center tw-gap-x-1 tw-no-underline focus:tw-outline-none tw-py-0.5 tw-pl-3 tw-pr-2 tw-rounded-md tw-text-gray-800 tw-text-base tw-leading-relaxed tw-font-medium tw-transition-colors tw-duration-200 tw-bg-transparent"
        :class="labelClass"
      >
        {{ label }}

        <k-icon name="chevron_down" size="18" class="tw-mt-1" />
      </a>
    </template>

    <div class="tw-rounded-2xl tw-shadow-glow">
      <div class="tw-rounded-2xl">
        <div class="tw-py-4 tw-pl-6 tw-pr-10 tw-bg-white tw-rounded-2xl tw-flex tw-flex-col tw-space-y-3 ">
          <slot />
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'k-menu',
  props: {
    label: {
      type: String,
      required: true
    },
    labelClass: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
