export const salesTrainee = {
  id: 'sales-trainee',
  name: 'Sales Trainee',
  description: `
      <p>Si čerstvý absolvent alebo mladý človek, ktorý hľadá výzvy a túži naštartovať kariéru v dynamickom svete obchodu? Hľadáme ťa! V našom tíme ti dáme možnosť rásť a učiť sa od skúsených profesionálov, pričom budeš mať na starosti kľúčovú časť predajného procesu.</p>

      <p><strong>Tvoja náplň práce bude:</strong></p>
      <ul>
        <li>Vyhľadávanie a oslovovanie nových klientov (B2B) – telefonicky, mailom a cez LinkedIn</li>
        <li>Komunikácia s ﬁrmami všetkých veľkostí – od menších hráčov až po veľké korporácie</li>
        <li>Práca v CRM systéme a aktívne sledovanie predajných príležitostí</li>
        <li>Dohadovanie stretnutí pre našich senior obchodníkov a podpora predajného tímu</li>
      </ul>

      <p><strong>Čo od teba očakávame?</strong></p>
      <ul>
        <li>Neváhaš zdvihnúť telefón a aktívne osloviť klienta</li>
        <li>Si komunikačný guru, ktorého len tak niečo neprekvapí</li>
        <li>Zaujímaš sa o obchod, motivujú ťa výsledky a máš ťah na bránku</li>
        <li>Fascinuje ťa IT svet a chceš v ňom pôsobiť v budúcnosti</li>
      </ul>

      <p><strong>Prečo si vybrať nás?</strong></p>
      <ul>
        <li>Získaš skúsenosti s komunikáciou v rôznych sektoroch a s firmami rozličnej veľkosti</li>
        <li>Môžeš sa tešiť na podporu od skúseného tímu a na prácu s modernými nástrojmi, ktoré ti uľahčia prácu</li>
        <li>Tvoja kariéra bude mať jasný smer – otvoríme ti dvere do sveta IT a predaja</li>
      </ul>

      <p><strong>Zamestnanecké výhody, benefity</strong></p>
      <ul>
        <li>Podpora vo vzdelávaní a osobnostnom raste</li>
        <li>Možnosť kariérneho rastu</li>
        <li>Motivačný systém odmien </li>
        <li>Možnosť získania obchodných skúseností, kontaktov a zručností<br></li>
        <li>Príjemná firemná atmosféra menšej firmy</li>
        <li>MultiSport karta</li>
      </ul>

      <p><strong>Platové podmienky</strong></p>
      <p>1600 € brutto/ mesiac (1100 € základná zložka + výkonnostný bonus). Zvýšenie platu po skúšobnej dobe.</p>
  `,
  language: 'Anglický (B2) a Slovenský',
  city: 'Bratislava',
  partner: null,
  profession: null,
  requiredLevels: [],
  states: [
    {
      value: 'PUBLISHED',
      displayString: 'Published'
    }
  ],
  expertises: [],
  tags: [],
  legalRelationType: null,
  remoteOfficePercentage: null,
  mdBudgetFrom: null,
  mdBudgetTo: null,
  monthlySalaryFrom: 1600,
  monthlySalaryTo: null,
  publishedAt: '2024-10-30',
  jobStartDate: '2024-12-01',
  projectLengthInMonths: null,
  responsibleHR: {
    name: 'Kristína Petreková',
    email: 'petrekova@objectify.sk'
  }
}

export const carrierProjects = [salesTrainee]
