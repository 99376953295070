import firebase from 'firebase/app'
import 'firebase/firestore'

import store from './store'

const actions = {
  initiateGetPosts(ctx) {
    ctx.commit('SET_LOADING_STATE', true)
    let now = Date.now()

    let initial = firebase
      .firestore()
      .collection('links')
      .where('published', '==', true)
      .where('publishedTimestamp', '<=', now)
      .orderBy('publishedTimestamp', 'desc')
      .limit(ctx.state.pageLength)

    initial.get().then(dataSnapshot => {
      let posts = []

      dataSnapshot.forEach(doc => {
        posts.push({ ...doc.data(), id: doc.id })
        ctx.commit('SET_AFTER_TIMESTAMP', doc.data().publishedTimestamp)
      })

      let mappedPosts = posts.map(function (e) {
        return {
          id: e.id,
          image: e.images ? e.images[e.images.length - 1] : 'not found',
          title: e.title,
          link: e.url,
          icon: e.favicons ? e.favicons[0] : '',
          siteName: e.siteName,
          timestamp: e.publishedTimestamp,
          timesClicked: e.timesClicked
        }
      })

      ctx.commit('SET_POSTS', mappedPosts)
      ctx.commit('SET_LOADING_STATE', false)

      if (dataSnapshot.size !== ctx.state.pageLength) ctx.commit('SET_END')
    })
  },
  getMorePosts(ctx) {
    let next = firebase
      .firestore()
      .collection('links')
      .where('published', '==', true)
      .where('publishedTimestamp', '<', ctx.state.startAfterTimestamp)
      .orderBy('publishedTimestamp', 'desc')
      .limit(ctx.state.pageLength)

    ctx.commit('SET_LOADING_STATE', true)

    next.get().then(dataSnapshot => {
      let posts = []
      dataSnapshot.forEach(doc => {
        posts.push({ ...doc.data(), id: doc.id })
        ctx.commit('SET_AFTER_TIMESTAMP', doc.data().publishedTimestamp)
      })

      let mappedPosts = posts.map(function (e) {
        return {
          id: e.id,
          image: e.images ? e.images[0] : 'not found',
          title: e.title,
          link: e.url,
          icon: e.favicons ? e.favicons[0] : '',
          siteName: e.siteName,
          timestamp: e.publishedTimestamp,
          timesClicked: e.timesClicked
        }
      })

      ctx.commit('APPEND_POSTS', mappedPosts)
      ctx.commit('SET_LOADING_STATE', false)

      if (dataSnapshot.size !== ctx.state.pageLength) ctx.commit('SET_END')
    })
  },
  postClicked(ctx, postID) {
    firebase
      .firestore()
      .collection('links')
      .doc(postID)
      .get()
      .then(postSnapshot => {
        /* eslint-disable no-console */
        let post = postSnapshot.data()

        let updatePostCall = firebase
          .firestore()
          .collection('links')
          .doc(postID)
        let updatedTimesClicked = post.timesClicked ? post.timesClicked + 1 : 1

        updatePostCall.update({
          timesClicked: updatedTimesClicked
        })
      })
  }
}
const state = {
  posts: [],

  pagesLoaded: 0,
  pageLength: 10,
  atEnd: false,
  startAfterTimestamp: null,

  loading: false
}
const getters = {}
const mutations = {
  SET_LOADING_STATE(state, newLoadingState) {
    state.loading = newLoadingState
  },
  SET_AFTER_TIMESTAMP(state, newAfterTimestamp) {
    state.startAfterTimestamp = newAfterTimestamp
  },
  SET_POSTS(state, posts) {
    state.posts = posts
    state.pagesLoaded = 1
  },
  APPEND_POSTS(state, newPosts) {
    let posts = state.posts
    newPosts.forEach(e => posts.push(e))

    state.posts = posts
    state.pagesLoaded = state.pagesLoaded + 1
  },
  SET_END(state) {
    state.atEnd = true
  }
}

store.registerModule('POSTS', {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
})
