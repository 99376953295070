<template>
  <div class="logo" :class="light ? 'logo--light' : ''">
    <template v-if="icon || isMobile">
      <img src="./assets/logo-web-mobile.svg" alt="Logo Koderia.sk" :width="iconSize" :height="iconSize" />
    </template>
    <template v-else>
      <img v-if="light" src="./assets/logo-web-light.svg" alt="Logo Koderia.sk" height="32" />
      <img v-else src="./assets/logo-web-dark.svg" alt="Logo Koderia.sk" height="32" />
    </template>
  </div>
</template>

<script>
import responsiveMixin from '@web/responsiveMixin'

export default {
  mixins: [responsiveMixin],
  props: {
    iconSize: {
      type: [Number, String],
      default: 32
    },
    light: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.logo {
  position: relative;
  display: inline-flex;
  align-items: center;
  user-select: none;
  color: $kod-black;
}

.logo--light {
  color: white;
}

.logo__icon {
  // width: 2rem;
  // height: 2rem;
  -webkit-user-drag: none;
  pointer-events: none;
  user-select: none;
}

.logo__text {
  margin-left: 0.75rem;
  font-family: "soleil", serif !important;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 800;
  padding-bottom: 0.313rem;
}

.logo__reg {
  font-family: "soleil", serif !important;
  font-size: 0.875rem;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
