<template>
  <div>
    <label class="language-switcher">{{ $t('profile.inputs.language') }}</label>
    <k-language-switcher-menu
      :selected-language="selectedLanguage"
      :languages="languages"
      @onLanguageSelect="$emit('select', $event)"
    />
  </div>
</template>

<script>
import KLanguageSwitcherMenu from '@web/components/core/k-language-switcher-menu'
import { DEFAULT_LOCALE } from '@web/constants/language'

export default {
  name: 'k-language-switcher',
  components: { KLanguageSwitcherMenu },
  props: {
    languageCode: {
      type: String,
      default: 'sk'
    }
  },
  emits: ['select'],
  data() {
    return {
      languages: [
        {
          text: 'Slovak',
          value: DEFAULT_LOCALE
        },
        {
          text: 'English',
          value: 'en'
        }
      ]
    }
  },
  computed: {
    selectedLanguage() {
      return this.languages.find(language => language.value === this.languageCode).text
    }
  }
}
</script>

<style lang="scss" scoped>
.language-switcher {
  font-size: 0.875rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  display: inline-block;
}
</style>
