<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      d="M12,8A12,12,0,1,0,24,20,12,12,0,0,0,12,8Zm4.873,17.656a.923.923,0,0,1-.518-.174c-3.019-1.819-6.532-1.9-10-1.185a3.125,3.125,0,0,1-.576.126.753.753,0,0,1-.765-.765.767.767,0,0,1,.658-.813c3.963-.876,8.013-.8,11.468,1.268a.818.818,0,0,1,.469.8.731.731,0,0,1-.735.745Zm1.3-3.174a1.2,1.2,0,0,1-.6-.2A15.681,15.681,0,0,0,6.034,20.856a1.993,1.993,0,0,1-.576.126.94.94,0,0,1-.939-.939.952.952,0,0,1,.75-1A16.275,16.275,0,0,1,10,18.384a16.938,16.938,0,0,1,8.565,2.2,1.009,1.009,0,0,1,.547.953A.938.938,0,0,1,18.174,22.482Zm1.5-3.687a1.113,1.113,0,0,1-.624-.189c-3.445-2.056-9.6-2.55-13.592-1.437a2.3,2.3,0,0,1-.624.126,1.117,1.117,0,0,1-1.127-1.142A1.143,1.143,0,0,1,4.548,15a20.28,20.28,0,0,1,5.685-.735,20.436,20.436,0,0,1,9.939,2.313,1.136,1.136,0,0,1,.624,1.094A1.121,1.121,0,0,1,19.674,18.8Z"
      transform="translate(0 -8)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#374151'
    },
    size: {
      type: [Number, String],
      default: 24
    }
  }
}
</script>
