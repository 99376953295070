import { api } from './config'

async function fetchUserDetail() {
  const { data } = await api.get('/candidates/my')

  return data
}

async function updateUserDetail(detail) {
  const { data } = await api.put('/candidates/my', detail)

  return data
}

async function updateUserNotificationSettings(settings) {
  const { data } = await api.put('/candidates/my/notification-settings', settings)

  return data
}

async function updateUserCvSettings(settings) {
  const { data } = await api.put(`/candidates/my/cv-settings`, settings)

  return data
}

async function applyUserForJob(jobApplication) {
  const { data } = await api.post(`/job-application/apply`, jobApplication)

  return data
}

async function applyUserForJobUploadCv(file) {
  const formData = new FormData()
  formData.append('file', file)

  const { data } = await api.post(`/job-application/cv-upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return data
}

export {
  fetchUserDetail,
  updateUserDetail,
  updateUserNotificationSettings,
  updateUserCvSettings,
  applyUserForJob,
  applyUserForJobUploadCv
}
