<script>
import { VTextField } from 'vuetify/lib'

export default {
  extends: VTextField,
  props: {
    // Custom
    invalid: {
      type: Boolean,
      default: false
    },

    title: {
      type: String
    },

    white: {
      type: Boolean,
      default: false
    },

    // Vuetify
    flat: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto'
    }
  },
  computed: {
    classes() {
      return {
        'k-input': true,
        'k-input--invalid': this.invalid,
        'k-input--white': this.white,
        ...VTextField.options.computed.classes.call(this)
      }
    }
  },

  methods: {
    /**
     * Do not render default vuetify label
     */
    genLabel() {
      return null
    },

    genCustomLabel() {
      if (this.$slots.label) {
        return this.$slots.label
      }

      if (this.label || this.title) {
        const isRequired = this.$attrs.required === '' || this.$attrs.required === true
        const inputClass = isRequired ? 'k-input__label k-input-required' : 'k-input__label'
        const data = { staticClass: inputClass, attrs: { for: this.computedId } }
        return this.$slots.label || this.$createElement('label', data, this.label || this.title)
      }

      return null
    },

    /**
     * Render the default vuetify input see render() in `/vuetify/lib/components/VInput/VInput.js`
     */
    genBaseInput() {
      return this.$createElement(
        'div',
        this.setTextColor(this.validationState, {
          staticClass: 'v-input',
          class: this.classes
        }),
        this.genContent()
      )
    }
  },
  render(h) {
    return h('div', {}, [this.genCustomLabel(), this.genBaseInput()])
  }
}
</script>

<style lang="scss" scoped>
.k-input__label {
  font-size: 0.875rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
  display: inline-block;
  color: $koderia-gray-800;
}

.k-input-required {
  &::after {
    content: "*";
    color: $koderia-red;
    margin-left: 0.25rem;
  }
}

.k-input.v-input {
  * {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }

  .v-label {
    color: #999999 !important;
  }

  .v-messages.error--text {
    color: #eb3300;
  }

  input,
  .v-label {
    margin: 0 !important;
    padding: 0 !important;
  }

  &.k-input--invalid,
  &.error--text {
    .v-input__slot {
      background: #fff !important;
      box-shadow: 0 0 0 4px #eb330033 !important;
      border: 1px solid #eb3300 !important;
    }
  }

  &.v-input--is-focused {
    .v-input__slot {
      background: #fff !important;
      border: 1px solid #1f2937 !important;
      box-shadow: 0 0 0 4px #f3f4f6 !important;
    }
  }

  &.v-input--is-disabled {
    .v-input__slot {
      background: #fff !important;
      border: 1px solid #d1d5db !important;
    }
  }

  &:not(.v-input--is-focused) {
    .v-input__slot:hover {
      background: #fff !important;
      border: 1px solid #d1d5db !important;
      box-shadow: 0 0 0 4px #f3f4f6 !important;
    }
  }

  .v-input__slot {
    padding: 0.625rem 0.875rem !important;
    background: $koderia-gray-100 !important;
    border: 1px solid $koderia-gray-100 !important;
    border-radius: 0.375rem !important;
    min-height: 2.875rem !important;
  }

  &.k-input--white {
    .v-input__slot {
      background: #fff !important;
    }

    &:not(.v-input--is-focused) {
      .v-input__slot {
        border: 1px solid #d1d5db !important;
      }
    }
  }
}
</style>
