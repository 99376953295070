import store from './../../store'

const initialState = {
  active: false,
  title: '',
  body: '',
  resolve: null,
  reject: null,
  inputType: 'text'
}

store.registerModule('PROMPT', {
  namespaced: true,
  state: Object.assign({}, initialState),
  mutations: {
    ACTIVATE(state, payload) {
      Object.assign(state, payload)
    },
    DEACTIVATE(state) {
      Object.assign(state, initialState)
    }
  },
  actions: {
    ASK: ({ commit }, { title, body, inputType }) => {
      return new Promise((resolve, reject) => {
        commit('ACTIVATE', {
          active: true,
          title,
          body,
          resolve,
          reject,
          inputType
        })
      })
    }
  }
})
