<template>
  <div class="btn-wrapper" :class="[color, { disabled: disabled }]">
    <div class="shadow" />

    <k-btn
      v-bind="$attrs"
      v-on="$listeners"
      :disabled="disabled"
      :color="color"
      class="tw-flex-auto tw-whitespace-no-wrap"
      :class="customClass"
    >
      <slot></slot>
    </k-btn>
  </div>
</template>

<script>
const AVAILABLE_APPEARANCE = [
  'primary',
  'primary-inverted',
  'secondary',
  'outline',
  'regular',
  'text',
  'red-outlined'
]

export default {
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: value => AVAILABLE_APPEARANCE.indexOf(value) !== -1
    },
    customClass: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-wrapper {
  position: relative;
  z-index: 10;
  display: inline-flex;

  .shadow {
    display: none;
    border-radius: 0.375rem;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.primary,
.primary-inverted,
.secondary {
  & > .shadow {
    display: block;
  }


  &.disabled {
    & > button {
      border: 1px solid $koderia-gray-400;
    }

    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-gray-400;
    }
  }
}

.primary,
.primary-inverted {
  & > .shadow {
    box-shadow: 0 3px 0 0 $koderia-green;
  }

  &:hover {
    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-green-dark;
    }
  }

  &:active {
    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-green;
    }
  }
}

.secondary {
  & > .shadow {
    box-shadow: 0 3px 0 0 $koderia-gray-400;
  }

  &:hover {
    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-gray-500;
    }
  }

  &:active {
    & > .shadow {
      box-shadow: 0 3px 0 0 $koderia-gray-400;
    }
  }
}
</style>
