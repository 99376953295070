import router from '@web/router'
import { getRouteLocale } from '@web/constants/language'
import Cookies from 'js-cookie'

export function redirectToOrigin() {
  router
    .push({
      name: 'Home',
      params: { locale: getRouteLocale() }
    })
    .catch(() => {
    })
}

export function redirectAfterLogin() {
  const redirectTo = Cookies.get('redirect.after_login')

  if (redirectTo) {
    setTimeout(() => {
      Cookies.remove('redirect.after_login')
    }, 250)

    try {
      const parsed = JSON.parse(redirectTo)
      router.push(parsed)
    } catch {
      // Try parse `redirect.after_login` cookie, if not an object then it should be a route name
      router.push({
        name: redirectTo,
        params: { locale: getRouteLocale() }
      })
    }
  } else {
    redirectToOrigin()
  }
}

export function redirectNewUserToFinishSignUp() {
  const { name: routeName } = router.currentRoute

  if (routeName !== 'finish-signup') {
    const in30Minutes = 1 / 48
    const redirectToAfterLogin = Cookies.get('redirect.after_login')

    if (redirectToAfterLogin) {
      Cookies.remove('redirect.after_login')
      Cookies.set('redirect.after_finish-signup', redirectToAfterLogin, { expires: in30Minutes })
    } else {
      Cookies.set('redirect.after_finish-signup', routeName, { expires: in30Minutes })
    }

    router.push({ name: 'finish-signup' })
  }
}

export function redirectAfterSuccessfulAfterSignUp() {
  const redirectTo = Cookies.get('redirect.after_finish-signup')

  if (redirectTo && !['finish-signup', 'auth'].includes(redirectTo)) {
    Cookies.remove('redirect.after_finish-signup')

    router.push({ name: redirectTo, params: { locale: getRouteLocale() } })
  } else {
    router.push({ name: 'Home', params: { locale: getRouteLocale() } })
  }
}

export function redirectUserToVerificationPage() {
  router.push({ name: 'verification', params: { locale: getRouteLocale() } })
}
