/* eslint-disable no-console */

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

import router from '../router'
import store from '../store'
import { DEFAULT_LOCALE } from '@web/constants/language'
import { programmerCalculator, testerCalculator } from '../addons/questionaire_calculator'
import { PROGRAMMER_PROFESSIONS, TESTER_PROFESSIONS } from '@web/constants/questionaire'

const getLocalePrefix = () => {
  const locale = store.getters.appLocale

  return locale === DEFAULT_LOCALE ? '' : `/${ locale }`
}

const actions = {
  initiateGetTechnologies(ctx) {
    ctx.commit('SET_LOADING_STATE', true)

    let technologies = []
    let technologiesPraxis = []

    firebase
      .firestore()
      .collection('enums')
      .doc('za_techs')
      .get()
      .then(dataSnapshot => {
        dataSnapshot.data().content.forEach(t => technologies.push(t))

        ctx.commit(
          'SET_TECHNOLOGIES',
          technologies.sort((a, b) => a.name.localeCompare(b.name))
        )

        technologiesPraxis = technologies.map(e => {
          return {
            name: e.name,
            praxis: 0
          }
        })

        ctx.commit('SET_TECHNOLOGIES_PRAXIS', technologiesPraxis)
        ctx.commit('SET_LOADING_STATE', false)
      })
  },
  setFormResultsProfession(ctx, profession) {
    ctx.commit('SET_FORM_RESULTS_PROFESSION', profession)
  },
  setFormResultsData(ctx, professionObject) {
    const { gdpr, ...data } = professionObject

    const profession = ctx.state.formResults.profession

    if (PROGRAMMER_PROFESSIONS.includes(profession)) {
      programmerCalculator.computeWage(data)
    } else if (TESTER_PROFESSIONS.includes(profession)) {
      testerCalculator.computeWage(data)
    }

    ctx.commit('SET_FORM_RESULTS_DATA', data)
  },
  saveProgrammerToDB(ctx, programmerObject) {
    ctx.commit('SET_LOADING_STATE', true)
    let savedProgrammer = JSON.parse(JSON.stringify(programmerObject))
    savedProgrammer.type = 'questionaire-interest'
    savedProgrammer.personType = 'programmer'
    delete savedProgrammer.technologiesPraxis
    delete savedProgrammer.technologiesKnown

    let firebaseProcessInterest = firebase.functions().httpsCallable('processQuestionaireInterest')
    firebaseProcessInterest(savedProgrammer)
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        ctx.commit('SET_LOADING_STATE', false)

        router.push({
          path: `${ getLocalePrefix() }/adekvatny-plat/dakujeme`,
          hash: '#adekvatny-plat-hotovo'
        })
      })
    return
  },
  saveTesterToDB(ctx, testerObject) {
    ctx.commit('SET_LOADING_STATE', true)

    let savedTester = JSON.parse(JSON.stringify(testerObject))
    savedTester.type = 'questionaire-interest'
    savedTester.personType = 'tester'

    let firebaseProcessInterest = firebase.functions().httpsCallable('processQuestionaireInterest')
    firebaseProcessInterest(savedTester)
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        ctx.commit('SET_LOADING_STATE', false)
        router.push({
          path: `${ getLocalePrefix() }/adekvatny-plat/dakujeme`,
          hash: '#adekvatny-plat-hotovo'
        })
      })

    return
  },
  saveCVObjectToDB(ctx, payload) {
    ctx.commit('SET_LOADING_STATE', true)

    const fileName = payload.file.name + '_' + makeid(32)
    let fileUploadTask = firebase
      .storage()
      .ref('/CV/' + fileName)
      .put(payload.file)

    const handleProgress = () => {
    }
    const handleError = error => void console.error(error)
    const handleComplete = async () => {
      // eslint-disable-next-line
      const { files, ...rest } = JSON.parse(JSON.stringify(payload))

      const data = {
        ...rest,
        type: 'questionaire-interest',
        cvFileName: fileName
      }

      try {
        await firebase.functions().httpsCallable('processQuestionaireInterest')(data)
      } catch (error) {
        handleError(error)
      } finally {
        ctx.commit('SET_LOADING_STATE', false)
        router.push({
          path: `${ getLocalePrefix() }/adekvatny-plat/dakujeme`,
          hash: '#adekvatny-plat-hotovo'
        })
      }
    }

    fileUploadTask.on('state_changed', handleProgress, handleError, handleComplete)
  },
  appendToQuestionaireObject(ctx, field) {
    ctx.commit('APPEND_TO_QUESTIONAIRE_OBJECT', field)
  }
}
const state = {
  questionaireInterestObject: {},
  technologies: [],
  technologiesPraxis: [],
  loading: false,
  formResults: {
    profession: '',
    data: {}
  }
}
const getters = {
  formResults: state => JSON.parse(JSON.stringify(state.formResults))
}
const mutations = {
  SET_TECHNOLOGIES(state, technologies) {
    state.technologies = technologies
  },
  SET_TECHNOLOGIES_PRAXIS(state, technologiesPraxis) {
    state.technologiesPraxis = technologiesPraxis
  },
  SET_LOADING_STATE(state, newLoadingState) {
    state.loading = newLoadingState
  },
  APPEND_TO_QUESTIONAIRE_OBJECT(state, field) {
    let newQuestionaireInterestObject = Object.assign(state.questionaireInterestObject, field)
    state.questionaireInterestObject = newQuestionaireInterestObject
  },
  SET_FORM_RESULTS_PROFESSION(state, profession) {
    state.formResults.profession = profession
  },
  SET_FORM_RESULTS_DATA(state, data) {
    state.formResults.data = data
  }
}

store.registerModule('QUESTIONAIRE', {
  namespaced: true,
  strict: false,
  state,
  actions,
  mutations,
  getters
})

//helpers
function makeid(length) {
  var text = ''
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}
